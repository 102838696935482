@font-face {
  font-family: "Roboto";
  src: url("../../public/fonts/Roboto-Bold.ttf") format("woff2"),
    url("../../public/fonts/Roboto-Bold.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}
.header {
  font-family: Roboto !important;
}
.list {
  font-family: Roboto !important;
}
.slide-container {
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 22));
  }
}
.slider {
  background: white;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 160s linear infinite;
  display: flex;
  width: calc(250px * 20);
}
.slider .slide {
  height: 100px;
  width: 250px;
  margin-left: 30px;
  margin-right: 30px;
}

.poster-slider {
  background: white;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: 250px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.poster-slider::before,
.poster-slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 250px;
  position: absolute;
  width: 167px;
  z-index: 2;
}
.poster-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.poster-slider::before {
  left: 0;
  top: 0;
}
.poster-slider .slide-track {
  animation: scroll 120s linear infinite;
  display: flex;
  width: calc(100px * 25);
}
.poster-slider .slide {
  height: 100px;
  width: 250px;
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 1225px) {
  .wrap-section {
    flex-direction: column;
    align-items: center;
  }
  .flex-section {
    width: 100% !important;
  }
}

@media only screen and (max-width: 460px) {
  .mobile-margin {
    margin: 10px !important;
  }
}

.icon-button:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.icon-button:active {
  filter: brightness(60%);
  cursor: pointer;
}

.letter {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 26px auto 0;
  padding: 24px;
  position: relative;
  width: 80%;
}
.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.letter:before {
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.letter:after {
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}
.grow:hover {
  transform: scale(0.96);
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
.grow:active {
  cursor: pointer;
}
.grow {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
